import type { LoaderFunction } from 'react-router';
import { Outlet } from 'react-router';
import { auth, sessionStorage } from '~/auth.server';

type LoaderData = {
  error: { message: string } | null;
};

export const loader: LoaderFunction = async ({ request }) => {
  await auth.isAuthenticated(request, {
    successRedirect: '/dashboard/projects',
  });
  const session = await sessionStorage.getSession(
    request.headers.get('Cookie'),
  );
  const error = session.get(auth.sessionErrorKey) as LoaderData['error'];
  return { error } as LoaderData;
};

export default function LoginPage() {
  return (
    <div className="page-content-wrapper small">
      <section className="section">
        <Outlet />
      </section>
    </div>
  );
}
